@import "../../theme/colors.scss";
@import "../../theme/breakpoints.scss";

.simple-section-container {
  // background: linear-gradient(
  //   131.23deg,
  //   #a89d74 0%,
  //   rgba(168, 157, 116, 0.5) 100%
  // );
  background-color: #adc2ff;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}
.simple-section {
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 10vh;
  padding-bottom: 10vh;
  display: flex;
  width: 1000px;
  color: #1c1c1c;
  background-color: #2b5ae8;
  margin: 40px 0;
  border-radius: 10px;
  @media (max-width: $xl) {
    padding-left: 5vw;
    padding-right: 5vw;
  }
  @media (max-width: $lg) {
    padding-left: 2.5vw;
    padding-right: 2.5vw;
    // display: none;
  }
  @media (max-width: $sm) {
    justify-content: flex-start;
    padding-top: 10vh;
    align-items: center;
    // height: 40vh;
  }
  .simple-section-title {
    font-size: 32px;
    // font-size: 48px;
    font-family: MontessartBold;
    color: white;
  }
  .simple-section-title:lang(ar) {
    font-family: TajawalBold;
  }
  .simple-section-button {
    padding: 20px 50px;
    margin-top: 30px;
    color: white;
    background-color: rebeccapurple;
    border-radius: 9px;
  }
  .simple-section-bullet-item {
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: $sm) {
    }
    .simple-section-bullet-item-title {
      font-size: 25px;
      font-family: MontessartBold;
      text-align: center;
      @media (max-width: $sm) {
        font-size: 18px;
        line-height: 40px;
      }
    }
    .simple-section-bullet-item-title:lang(ar) {
      font-family: TajawalBold;
    }
  }
  .icon-bullet {
    margin: 0 20px 0 20px;
    @media (max-width: $sm) {
      display: none;
    }
  }
  .icon-bullet-mobile {
    display: none;
    @media (max-width: $sm) {
      display: unset;
      margin: 0 5px 0 5px;
    }
  }
  .simple-section-bullets {
    flex-direction: row;
    display: flex;
    margin-top: 40px;
    font-size: 18px;

    justify-content: center;
    @media (max-width: $sm) {
      font-size: 14px;
      text-align: left;

      max-width: 100%;
      flex-direction: column;
    }
  }
  .simple-section-qr-code {
    width: 150px;
  }
  .simple-section-right {
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    padding-top: 30px;
    padding-bottom: 30px;
    width: 100%;
    padding: 0 3vw 0 3vw;
  }
  .simple-section-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    align-items: center;
    margin-top: 40px;
    align-items: center;
    text-align: start;
    column-gap: 30px;
    .simple-section-description {
      width: 260px;
      .simple-section-description-title {
        font-family: MontessartBold;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 8px;
      }
      .simple-section-description-title:lang(ar) {
        font-family: TajawalBold;
      }
      .simple-section-description-sub-title {
        font-size: 12px;
        line-height: 20px;
      }
    }
  }

  .simple-section-mobile {
    width: 390px;
    justify-content: space-between;
    flex-direction: row;
    display: flex;
    align-items: center;
    margin-top: 40px;
    overflow: hidden;
    column-gap: 10px;
    @media (max-width: $lg) {
      align-self: center;
    }
    @media (max-width: $xl) {
      width: 240px;
    }
    @media (max-width: $lg) {
      // width: 80vw;
      flex-direction: row;
      justify-content: center !important;
      align-items: center;
      display: flex;
      // background-color: aqua;
    }
    .simple-section-mobile-icon {
      width: 110px;
      img {
        @media (max-width: $lg) {
          align-self: center;
          width: 110px;
        }
        @media (max-width: $xl) {
        }
        @media (max-width: $sm) {
        }
      }
    }
  }
}
