@import "../..//theme/breakpoints.scss";

.text-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
}
.page-container {
  max-width: 1000px;
  align-self: center;
  text-align: center;
  text-transform: capitalize;
  text-rendering: geometricPrecision;
  text-align-last: center;
}
