@import "../../theme/colors.scss";
@import "../../theme/breakpoints.scss";
.security-section-container {
  // background: url(data:image/svg+xml;base64,PCEtLSBzdmc6IGZpcnN0IGxheWVyIC0tPgo8c3ZnIHZpZXdCb3g9JzAgMCA1MDAgNTAwJyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnPgogIDxmaWx0ZXIgaWQ9J25vaXNlRmlsdGVyJz4KICAgIDxmZVR1cmJ1bGVuY2UKICAgICAgdHlwZT0ndHVyYnVsZW5jZScKICAgICAgYmFzZUZyZXF1ZW5jeT0nMC41JwogICAgICBudW1PY3RhdmVzPScxJwogICAgICBzdGl0Y2hUaWxlcz0nc3RpdGNoJy8+CiAgPC9maWx0ZXI+CgogIDxmaWx0ZXIgaWQ9Imx1bWluYW5jZVRvQWxwaGEiPgogICAgICA8ZmVDb2xvck1hdHJpeCBpbj0iU291cmNlR3JhcGhpYyIgdHlwZT0ibHVtaW5hbmNlVG9BbHBoYSIKICAgICAgICAgICAgICAgICAgICAgIHZhbHVlcz0iMC4yIiAvPgogIDwvZmlsdGVyPgoKICA8ZyBmaWx0ZXI9InVybCgjbHVtaW5hbmNlVG9BbHBoYSkiPgogICAgPHJlY3QKICAgICAgd2lkdGg9JzEwMCUnCiAgICAgIGhlaWdodD0nMTAwJScKICAgICAgZmlsdGVyPSd1cmwoI25vaXNlRmlsdGVyKScgLz4KICA8L2c+Cjwvc3ZnPgo=),
  //   linear-gradient(136.56deg, #171616 0%, RGB(23, 22, 22, 0.75) 100%);
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #2d2d2d;
}
.security-section {
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 10vh;
  padding-bottom: 10vh;
  display: flex;
  width: 1000px;
  color: white;

  @media (max-width: $xl) {
    padding-left: 5vw;
    padding-right: 5vw;
  }
  @media (max-width: $lg) {
    padding-left: 2.5vw;
    padding-right: 2.5vw;
    // display: none;
  }
  @media (max-width: $sm) {
    justify-content: flex-start;
    padding-top: 10vh;
    align-items: center;
  }

  .security-section-title {
    margin-block: unset;
    margin: 20px 0 20px 0;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
  }
  .security-section-title:lang(ar) {
    letter-spacing: 0;
  }
  .security-section-subtitle {
    font-family: MontessartBold;
    font-size: 48px;
    line-height: 48px;

    @media (max-width: $lg) {
      font-size: 24px;
      line-height: 30px;
      width: 80%;
    }
  }
  .security-section-subtitle:lang(ar) {
    font-family: TajawalBold;
  }

  .security-section-list {
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: 50px;
    align-items: start;
    @media (max-width: $lg) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      row-gap: 20px;
    }
    .security-section-list-item {
      flex-direction: column;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30%;
      @media (max-width: $lg) {
        width: 80%;
      }
      .security-section-list-item-icon {
        width: 150px;
        height: 130px;
      }
      .security-section-list-item-title {
        font-family: MontessartBold;
        font-size: 16px;
        line-height: 30px;
        margin-top: 10px;
        @media (max-width: $sm) {
          font-family: Montessart;
        }
      }
      .security-section-list-item-title:lang(ar) {
        font-family: TajawalBold;
        @media (max-width: $sm) {
          font-family: Tajawal;
        }
      }
    }
  }
}
