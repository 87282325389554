@import "../../theme/colors.scss";
@import "../../theme/breakpoints.scss";

.site-footer-container {
  width: 1000px;
  justify-content: center;
  align-items: center;
  display: flex;
}
.site-footer-mobile-bottom-new {
  display: flex;
  align-items: center;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 20px 0 90px 0 !important;
  font-family: Montessart;
  background-color: white;
  flex-direction: column;
  padding-inline: 20px !important;
  .site-footer-row {
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
    width: 100%;
    align-items: flex-start;
    @media (max-width: $xl) {
      flex-direction: column;
    }
  }
  .site-footer-mobile-bottom-container {
    width: 100%;
    // justify-content: flex-start;
    display: flex;
    flex-direction: column;
  }
  .first-section-stores-container {
    @media (max-width: $xl) {
      width: 100%;
      // background-color: aqua;
      overflow: hidden;
      column-gap: 20px;
      display: flex;
      margin-top: 20px;
      justify-content: center;
      .first-section-stores-icon {
        width: 150px;
        img {
          width: 150px;
        }
        @media (max-width: $sm) {
          width: 120px;

          img {
            width: 120px;
          }
        }
      }
    }
  }
  .site-footer-bottom-right {
    display: flex;
    flex-direction: column;
    width: 50px;
    width: 20%;
    justify-content: center;
    row-gap: 20px;
    @media (max-width: $md) {
      margin-top: 20px;
      width: 380px;
      align-self: center;
    }
    @media (max-width: $sm) {
      width: 280px;
    }
    .site-footer-bottom-right-text {
      font-family: MontessartBold;
      letter-spacing: 0.125rem;
      text-transform: uppercase;
      margin-top: 20px;
    }
    .site-footer-bottom-right-text:lang(ar) {
      font-family: TajawalBold;
      letter-spacing: 0;
      text-align: right;
    }
    .site-footer-bottom-right-description {
      // color: black !important;
      color: #6e7277 !important;
      font-size: 14px;
      // letter-spacing: 1px;
    }
    .site-footer-bottom-right-description:lang(ar) {
      direction: ltr;
      text-align: right;
    }
    .site-footer-bottom-right-logos-container {
      width: 100%;
      align-self: center;
      justify-content: space-between;
      display: flex;
      @media (max-width: $md) {
        align-self: center;
        width: 65%;
        // background-color: rebeccapurple;
      }
      @media (max-width: $sm) {
        // width: 90%;
      }
      .site-footer-bottom-logo {
        width: 20px;
      }
    }
    .site-footer-bottom-store {
      width: 170px;
    }
  }
  .site-footer-bottom:lang(ar) {
    text-align: right !important;
  }
  .site-footer-bottom-text {
    font-size: 14px;
    line-height: 27px;
    text-align: start !important;
    color: #6e7277;
    width: 100%;
    text-align: left !important;
    font-family: Montessart;
  }
  .site-footer-bottom-text:lang(ar) {
    font-family: Tajawal !important;
  }
}
.site-footer {
  display: flex;
  align-items: center;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 60px 0 60px 0 !important;
  font-family: Montessart;
  background-color: #f9f9f9;
  @media (max-width: $xl) {
    padding: 60px 50px 0px 50px !important;
  }
  @media (max-width: $lg) {
    padding-inline: 20px !important;
  }
  @media (max-width: $sm) {
    background-color: #f9f9f9;
  }

  .site-footer-row {
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
    width: 100%;
    align-items: flex-start;
    @media (max-width: $xl) {
      flex-direction: column;
    }
  }

  .site-footer-logo-title {
    justify-content: flex-start;
    display: flex;
    align-items: center;
    flex-direction: row !important;
    flex-flow: unset;
  }

  .site-footer-logo {
    width: 50px;
  }
  .site-footer-title {
    font-size: 20px;
    line-height: 32px;
    margin-left: 20px;
    font-family: Montessart;
    text-align: left;
  }
  .site-footer-title:lang(ar) {
    font-family: Tajawal;
    margin-left: 0px;
    margin-right: 20px;
    text-align: right;
  }
  .site-footer-bottom {
    width: 100%;
    flex-direction: row;
    display: flex;
    text-align: left;
    margin-top: 50px;
    margin-bottom: 50px;

    justify-content: space-between;
    align-items: flex-start;

    .site-footer-category {
      justify-content: center;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      row-gap: 20px;
      .site-footer-category-title {
        font-family: MontessartBold;
        letter-spacing: 0.125rem;
        text-transform: uppercase;
      }
      .site-footer-category-title:lang(ar) {
        font-family: TajawalBold;
        letter-spacing: 0;
        width: 200px;
        text-align: right;
      }
      .site-footer-category-sub-title {
        text-transform: capitalize;
        color: #6e7277;
        font-size: 16px;
        line-height: 24px;
      }
    }
    .side-footer-bottom-left {
      flex-direction: row;
      display: flex;
      text-align: left;
      justify-content: flex-start;
      align-items: flex-start;
      column-gap: 150px;
      width: 80%;
    }
  }
  .site-footer-bottom-right {
    display: flex;
    flex-direction: column;
    width: 50px;
    width: 20%;
    justify-content: center;
    row-gap: 20px;
    @media (max-width: $md) {
      // background-color: red;
      margin-top: 20px;
      width: 100%;
      justify-content: flex-start;
      text-align: start !important;
    }
    .site-footer-bottom-right-text {
      font-family: MontessartBold;
      letter-spacing: 0.125rem;
      text-transform: uppercase;
    }
    .site-footer-bottom-right-text:lang(ar) {
      font-family: TajawalBold;
      letter-spacing: 0;
      text-align: right;
    }
    .site-footer-bottom-right-description {
      color: black !important;
      // letter-spacing: 1px;
    }
    .site-footer-bottom-right-description:lang(ar) {
      direction: ltr;
      text-align: right;
    }
    .site-footer-bottom-right-logos-container {
      width: 100%;
      align-self: center;
      justify-content: space-between;
      display: flex;
      @media (max-width: $md) {
        align-self: flex-start;
        width: 40%;
      }
      @media (max-width: $sm) {
        width: 40%;
      }
      .site-footer-bottom-logo {
        width: 15px;
      }
    }
    .site-footer-bottom-store {
      width: 170px;
    }
  }
  .site-footer-bottom:lang(ar) {
    text-align: right !important;
  }
  .site-footer-mobile-bottom-container {
    width: 100%;
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
  }
  .site-footer-mobile-bottom {
    width: 340px;
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
    gap: 12px;
    .site-footer-mobile-bottom-title {
      color: black;
      letter-spacing: 0.1em;
      width: 20%;
      text-align: start;
      @media (max-width: $sm) {
        width: 30%;
      }
      @media (max-width: $xs) {
      }
    }
    .site-footer-mobile-bottom-subtitle-container {
      gap: 16px;
      display: flex;
      flex-direction: column;
      text-align: left;
      .site-footer-mobile-bottom-subtitle {
        color: #6e7277;
      }
    }
  }
  .site-footer-mobile-bottom:lang(ar) {
    .site-footer-mobile-bottom-subtitle-container {
      text-align: right;
      margin-left: 0px !important;
    }
  }
  .site-footer-bottom-text {
    font-size: 14px;
    line-height: 27px;
    text-align: start !important;
    color: #6e7277;
    width: 100%;
    text-align: left !important;
    font-family: Montessart;
    margin-bottom: 20px;
  }
  .site-footer-bottom-text:lang(ar) {
    font-family: Tajawal !important;
  }
}

.site-footer:lang(ar) {
  font-family: Tajawal !important;
}
