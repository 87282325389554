@import "../../theme/colors.scss";
@import "../../theme/breakpoints.scss";
.container-second-section {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;
}
.second-section-mobile {
  @media (max-width: $sm) {
    justify-content: flex-start;
    align-items: flex-start;
    max-width: 100%;
    padding: 40px 20px;
  }
  .second-section-mobile-title {
    margin-block: unset;
    margin: 20px 0 20px 0;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 25px;
    text-transform: uppercase;
    text-align: start;
  }

  .second-section-mobile-sub-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    align-items: center;
    .second-section-mobile-subtitle {
      font-family: MontessartBold;
      font-size: 20px;
      text-align: start;
      max-width: 60%;
    }
    .second-section-mobile-subtitle:lang(ar) {
      font-family: TajawalBold;
    }
    .second-section-mobile-sub-container-icon {
      width: 190px;
      @media (max-width: $xl) {
        width: 100px;
      }
    }
  }

  .second-section-mobile-row {
    display: flex;
    flex-direction: row;
    padding-top: 25px;
    @media (max-width: $lg) {
      flex-direction: column;
    }
  }
  .divider {
    background-color: gray;
    height: 1px;
  }
  .second-section-mobile-row-items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 25px;
    margin-bottom: 25px;

    .second-section-mobile-row-item-title {
      font-family: MontessartBold;
      font-size: 18px;
      line-height: 30px;
      text-align: start;
    }
    .second-section-mobile-row-item-title:lang(ar) {
      font-family: TajawalBold;
    }
    .second-section-mobile-row-item-sub-title {
      font-size: 16px;
      line-height: 24px;
      text-align: start;
    }
  }
}
