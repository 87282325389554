@import "../..//theme/breakpoints.scss";

.App {
  text-align: center;
  background-color: white;
  // overflow-y: scroll;
  // scroll-snap-type: y mandatory;
}

.img-size {
  width: 200px;
  margin: 0 80px;
}
.scroll-section {
  display: flex;
  align-items: center;
  justify-content: center;
  scroll-snap-align: start;
}
.video-container {
  // height: 20% !important;
  width: 100%;
  height: calc(100vh - 120px);
}
.video-player {
  object-fit: fill;
  width: 100%;
  height: 100%;
}
.float {
  display: none;
  @media (max-width: 1023px) {
    position: fixed;
    width: 100%;
    height: 48px;
    bottom: 20px;
    right: 0;
    left: 0;
    // background-color: #0c9;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    display: unset;
    // box-shadow: 2px 2px 3px #999;
    align-self: center;
    .float-icon {
      width: 80%;
      height: 48px;
    }
  }
}

.my-float {
  margin-top: 22px;
}
