@import "../../theme/colors.scss";
@import "../../theme/breakpoints.scss";

.mobile-header {
  color: "white";
  display: flex;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 9999;
  top: 0;
  align-items: center;
  background-color: rgb(51, 102, 255);
  position: sticky;
  padding-inline: 0;
  display: flex;
  justify-content: space-between;
  .mobile-header-row {
    justify-content: space-between !important;
    flex-direction: row !important;
    display: flex;
    width: 100%;
    // background-color: white !important;
    align-items: center;
    padding: 0 20px !important;
  }
  .mobile-header-port {
    background-color: $primary;
    padding: 10px 5px 10px 5px;
    color: white;
    border-radius: 10px;
  }
  .col-logo {
    justify-content: flex-start;
    width: 100%;
    // background-color: green;
    .ant-image {
      align-self: center;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }
  .header-left {
    display: flex;
    align-items: center;
  }
  .mobile-header-logo {
    width: 100px;
  }
  .logo {
    font-size: 24px;
    color: #1890ff;
  }

  .header-right {
    display: flex;
    align-items: center;
  }
  .menu-button {
    background: none;
    border: none;
    padding: 0;
    color: white;
  }
}

.ant-dropdown-menu-title-content {
  justify-content: center;
  display: flex;
}
