@import "../../theme/colors.scss";
@import "../../theme/breakpoints.scss";

.row-section-container {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #f9f9f9;
  // background-color: red;
  padding: 100px 0 100px 0;
  @media (max-width: $sm) {
    padding: 40px 0 40px 0;
  }
}
.row-section-title {
  font-family: MontessartBold;
  font-size: 48px;
  line-height: 40px;
  color: black;
  padding-top: 30px;
  margin: 0;
  padding: 0;
  @media (max-width: $md) {
    font-size: 30px;
    padding: 0 30px;
  }
}
.row-section-title:lang(ar) {
  font-family: TajawalBold;
}

.row-section {
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
  // padding-bottom: 3vh;
  align-items: stretch;
  flex-direction: row;
  width: 1000px;
  column-gap: 100px;

  @media (max-width: $xl) {
  }
  @media (max-width: $lg) {
  }
  @media (max-width: $sm) {
    // padding: 5vh 5vw 5vh 5vw;
  }
  @media (max-width: $xs) {
    // padding: 5vh 5vw 5vh 5vw;
  }
  .row-section-list {
    width: 100%;
    align-items: center;
    justify-content: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 15px;
    column-gap: 10px;
    margin-top: 60px;
    @media (max-width: $xl) {
      align-items: center;
      justify-content: center;
    }
    @media (max-width: $sm) {
      row-gap: 20px;
    }
  }
  .row-section-item {
    width: 242px;
    height: 190px;
    background-color: white;
    // margin-bottom: 3vh;
    align-items: flex-start;
    justify-content: center;
    display: flex;
    flex-direction: column;
    border-radius: 9px;
    position: relative;
    padding: 0 40px 0 10px;
    overflow: hidden;
    -webkit-animation: fadeIn 0.6s linear;
    animation: fadeIn 0.6s linear;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    @media (max-width: $xl) {
      // width: 80%;
      width: 95%;
      padding: 0 90px 0 20px;
      height: 150px;
      // margin: 0 10% 0 5%;
    }
    @media (max-width: $lg) {
      padding: 0 80px 0 20px;
    }
    @media (max-width: $sm) {
      width: 90%;
      padding: 40px 20px 20px 20px;
      height: auto;
      // margin: 0 10% 0 5%;
    }
  }
  .row-section-item:nth-child(1) {
    -webkit-animation-delay: 0.26s;
    animation-delay: 0.26s;
  }
  .row-section-item:nth-child(2) {
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
  }
  .row-section-item:nth-child(3) {
    -webkit-animation-delay: 0.76s;
    animation-delay: 0.76s;
  }
  .row-section-item:nth-child(4) {
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
  }

  .row-section-item:nth-child(5) {
    -webkit-animation-delay: 1.26s;
    animation-delay: 1.26s;
  }
  .row-section-item:nth-child(6) {
    -webkit-animation-delay: 1.6s;
    animation-delay: 1.6s;
  }
  .row-section-item:nth-child(7) {
    -webkit-animation-delay: 1.76s;
    animation-delay: 1.76s;
  }

  .row-section-item:lang(ar) {
    @media (max-width: $sm) {
      margin: 0 5% 0 10%;
    }
  }
  .row-section-row-item-continer {
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
  .row-section-row-item-number {
    position: absolute;
    right: -14px;
    // height: 100%;
    font-size: 70px;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  }
  .row-section-row-item-icon {
    width: 25px;
    // position: absolute;
    // left: -35px;
    align-self: flex-start;

    @media (max-width: $sm) {
      left: unset;
      right: -20px;
      top: 20px;
      width: 40px;
    }
  }

  .row-section-row-item-sub-title {
    text-align: left;
    font-size: 16px;
    line-height: 1.5rem;
    letter-spacing: -0.025em;
    @media (max-width: $xl) {
      font-size: 16px;
    }
    @media (max-width: $lg) {
      font-size: 16px;
    }
    @media (max-width: $sm) {
      font-size: 14px;
      text-align: left;
    }
  }
  .row-section-row-item-sub-title:lang(ar) {
    text-align: right;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  75% {
    opacity: 0.6;
  }

  100% {
    opacity: 2;
  }
}
