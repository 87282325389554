@import "./breakpoints.scss";
@import "./colors.scss";

body:lang(ar) {
  font-family: "Almarai";
}

h1.ant-typography,
h2.ant-typography,
h3.ant-typography {
  color: blue;
}

h1.ant-typography {
  font-size: 25px;
}

h2.ant-typography {
  font-size: 20px;
}

h3.ant-typography {
  font-size: 18px;
}

h4.ant-typography {
  font-size: 16px;
  color: black;
}

h1.ant-typography,
h2.ant-typography,
h3.ant-typography,
label {
  color: $primary;
  font-weight: normal;
}
p.ant-typography {
  font-size: 18px;
}
a,
span.ant-typography {
  font-size: 14px;
}

@media (max-width: $md) {
  h1.ant-typography {
    font-size: 22px;
  }

  h2.ant-typography {
    font-size: 18px;
  }

  h3.ant-typography {
    font-size: 16px;
  }

  h4.ant-typography {
    font-size: 14px;
  }

  p.ant-typography {
    font-size: 16px;
  }

  a,
  span.ant-typography {
    font-size: 16px;
  }
  span.ant-typography.ant-typography-secondary {
    font-size: 11px;
  }
}

@media (max-width: $sm) {
  h1.ant-typography {
    font-size: 22px;
  }
}
