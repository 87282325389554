@import "../../theme/colors.scss";
@import "../../theme/breakpoints.scss";
.partners-section-container {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 80px;
}
.partner-list-title {
  font-family: MontessartBold;
  font-size: 18px;
  line-height: 30px;
  margin-bottom: 30px;
}
.partner-list-container {
  width: 100%;
  overflow: hidden;
  padding-bottom: 70px;
  scroll-behavior: smooth;
}
.partner-list-container .ant-carousel .slick-list .slick-slide {
  pointer-events: none;
  border: none;
}
.partner-list-container .slick-slide div {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  scroll-behavior: smooth;
  border: none;
  pointer-events: none;
}

.partner-list-container img {
  height: 90px;
  width: 120px;
  align-self: center;
  object-fit: contain;
  pointer-events: none;
}
