@import "../../theme/colors.scss";
@import "../../theme/breakpoints.scss";

.second-section-sontainer {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
}
.second-section {
  justify-content: space-between;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 1000px;
  display: flex;
  padding: 80px 0 80px 0;
  color: #1c1c1c !important;
  @media (max-width: $xl) {
    padding-left: 5vw;
    padding-right: 5vw;
  }
  @media (max-width: $lg) {
    padding-left: 2.5vw;
    padding-right: 2.5vw;
  }
  @media (max-width: $sm) {
    justify-content: flex-start;
    padding-top: 10vh;
    align-items: center;
  }
  .second-section-right {
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    // padding-top: 30px;
    // padding-bottom: 30px;
  }
  .second-section-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    // margin-top: 2vh;
    align-items: center;
    margin-top: 40px;
    @media (max-width: $lg) {
      // display: none;
      flex-direction: column;
    }
  }
  .second-section-left {
    // height: 40vh;
    width: 450px;
    // height: 270px;
    @media (max-width: $xl) {
      // height: 30vh;
    }
  }
  .second-section-right-row {
    flex-direction: row;
    display: flex;
  }
  .second-section-right-row-items {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // margin-left: 30px;
    text-align: start;

    .second-section-row-item-title {
      font-family: MontessartBold;
      font-size: 18px;
      line-height: 30px;
    }
    .second-section-row-item-title:lang(ar) {
      font-family: TajawalBold;
    }
    .second-section-row-item-sub-title {
      font-size: 16px;
      line-height: 24px;
    }
  }
}
.second-section-title {
  margin-block: unset;
  margin: 20px 0 20px 0;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 25px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
.second-section-title:lang(ar) {
  letter-spacing: 0;
  // font-family: Tajawal;
}
.second-section-subtitle {
  font-family: MontessartBold;
  font-size: 48px;
  line-height: 48px;
}
.second-section-subtitle:lang(ar) {
  letter-spacing: 0;
  font-family: TajawalBold;
}

.second-section-right-icon {
  width: 170px;
  @media (max-width: $xl) {
    // width: 20px;
  }
}
