@import "../../theme/colors.scss";
@import "../../theme/breakpoints.scss";

.info-section-container {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #f9f9f9;
  padding: 100px 0 100px 0;
  @media (max-width: $sm) {
    padding: 40px 0 40px 0;
  }
}
.info-section-title {
  font-family: MontessartBold;
  font-size: 48px;
  line-height: 40px;
  color: black;
  padding-top: 30px;
  margin: 0;
  padding: 0;
  @media (max-width: $md) {
    font-size: 30px;
    padding: 0 30px;
  }
}
.info-section-title:lang(ar) {
  font-family: TajawalBold;
}

.info-section {
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
  // padding-bottom: 3vh;
  align-items: stretch;
  flex-direction: row;
  width: 1000px;
  column-gap: 100px;

  @media (max-width: $xl) {
  }
  @media (max-width: $lg) {
  }
  @media (max-width: $sm) {
    // padding: 5vh 5vw 5vh 5vw;
  }
  @media (max-width: $xs) {
    // padding: 5vh 5vw 5vh 5vw;
  }
  .info-section-list {
    width: 100%;
    align-items: center;
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 40px;
    margin-top: 60px;
    @media (max-width: $xl) {
      align-items: center;
      justify-content: center;
    }
    @media (max-width: $sm) {
      row-gap: 20px;
    }
  }
  .info-section-item {
    width: 490px;
    height: 250px;
    background-color: white;
    // margin-bottom: 3vh;
    align-items: flex-start;
    justify-content: center;
    display: flex;
    flex-direction: column;
    border-radius: 30px;
    position: relative;
    padding: 0 50px 0 60px;
    @media (max-width: $xl) {
      width: 80%;
      padding: 0 90px 0 90px;
      height: 150px;
      // margin: 0 10% 0 5%;
    }
    @media (max-width: $lg) {
      padding: 0 80px 0 80px;
    }
    @media (max-width: $sm) {
      width: 90%;
      padding: 40px 20px 20px 20px;
      height: auto;
      margin: 0 10% 0 5%;
    }
  }
  .info-section-item:lang(ar) {
    @media (max-width: $sm) {
      margin: 0 5% 0 10%;
    }
  }

  .info-section-row-item-icon {
    width: 70px;
    position: absolute;
    left: -35px;
    align-self: center;

    @media (max-width: $sm) {
      left: unset;
      right: -20px;
      top: 20px;
      width: 40px;
    }
  }
  .info-section-item:nth-child(even) {
    .info-section-row-item-icon {
      position: absolute;
      left: unset;
      right: -35px;
      @media (max-width: $sm) {
        left: unset;
        right: -20px;
        top: 20px;
        width: 40px;
      }
    }
  }
  .info-section-item:lang(ar) {
    .info-section-row-item-icon {
      right: -35px;
      @media (max-width: $sm) {
        left: -20px;
        right: unset;
        top: 20px;
        width: 40px;
      }
    }
  }
  .info-section-item:nth-child(even):lang(ar) {
    .info-section-row-item-icon {
      left: -35px;
      right: unset;
      @media (max-width: $sm) {
        left: -20px;
        right: unset;
        top: 20px;
        width: 40px;
      }
    }
  }
  .info-section-row-item-title {
    text-align: left;
    font-family: MontessartBold;
    font-size: 30px;
    // line-height: 1rem;
    margin-bottom: 10px;
    @media (max-width: $xl) {
      font-size: 20px;
      text-align: center;
    }
    @media (max-width: $lg) {
      font-size: 20px;
      line-height: 1.5rem;
      text-align: left;
    }
    @media (max-width: $sm) {
    }
  }
  .info-section-row-item-title:lang(ar) {
    font-family: TajawalBold;
  }
  .info-section-row-item-sub-title {
    text-align: left;
    font-size: 14px;
    line-height: 1.5rem;
    letter-spacing: -0.025em;
    @media (max-width: $xl) {
      font-size: 16px;
    }
    @media (max-width: $lg) {
      font-size: 16px;
    }
    @media (max-width: $sm) {
      font-size: 14px;
      text-align: left;
    }
  }
  .info-section-row-item-sub-title:lang(ar) {
    text-align: right;
  }
}
