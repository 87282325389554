@import "../../theme/colors.scss";
@import "../../theme/breakpoints.scss";

.boxes-section-container {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #f9f9f9;
}

.boxes-section-list {
  width: 100%;
  align-items: center;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 10px;
  margin-top: 20px;
  @media (max-width: $xl) {
    align-items: center;
    justify-content: center;
  }
}
.boxes-section {
  row-gap: 40px;
  padding: 20px 0vw 20px 0vw;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 1000px;
  @media (max-width: $xl) {
    width: 100%;
    padding: 20px 10px 20px 10px;
  }
  @media (max-width: $lg) {
  }
  @media (max-width: $sm) {
    padding: 20px 10px 20px 10px;
    row-gap: 20px;
  }
  @media (max-width: $xs) {
    // padding: 20px 5vw 20px 5vw;
  }
  .boxes-section-item {
    width: calc(50% - 20px);
    background-color: black;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    border-radius: 50px;
    padding: 40px 40px 40px 40px;
    row-gap: 10px;
    color: white;
    text-align: center;
    align-items: center;
    @media (max-width: $xl) {
      // width: 100%;
      align-items: center;
    }
    @media (max-width: $lg) {
      width: calc(100%);
    }
    @media (max-width: $sm) {
      align-items: center;
      width: calc(100%);
      padding: 25px 15px 25px 15px;
      border-radius: 40px;
    }
    .boxes-section-row-item-title {
      font-family: MontessartBold;
      font-size: 28px;
      line-height: 2rem;
      width: 100%;
      text-align: center;
      align-self: center;
      align-items: center;
      @media (max-width: $xl) {
        line-height: unset;
      }
      @media (max-width: $lg) {
      }
      @media (max-width: $sm) {
        font-size: 19px;
      }
    }
    .boxes-section-row-item-title:lang(ar) {
      font-family: TajawalBold;
    }
    .boxes-section-row-item-sub-title {
      font-size: 16px;
      line-height: 1.5rem;
      letter-spacing: -0.025em;
      margin-top: 10px;
      @media (max-width: $xl) {
        // font-size: 16px;
      }
      @media (max-width: $lg) {
        // font-size: 16px;
      }
      @media (max-width: $sm) {
        font-size: 12px;
      }
    }
    .boxes-section-image {
      width: 100%;
      height: auto;
      margin: 30px 0;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
  .boxes-section-item:nth-child(1) {
    background-color: #3366ff;
  }
  .boxes-section-item:nth-child(2) {
    background-color: #ff2c4d;
  }
  .boxes-section-item:nth-child(3) {
    background-color: #00b388;
  }
  .boxes-section-item:nth-child(4) {
    background-color: #ff9a3a;
  }

  .boxes-section-title {
    font-family: MontessartBold;
    font-size: 48px;
    line-height: 40px;
    padding-top: 30px;
    text-align: center;
    align-self: center;
    color: black;
    width: 100%;
    @media (max-width: $md) {
      font-size: 30px;
    }
  }

  .boxes-section-title:lang(ar) {
    font-family: TajawalBold;
  }
}
