@import "../../theme/colors.scss";
@import "../../theme/breakpoints.scss";

.site-header {
  display: flex;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 9999;
  top: 0;
  align-items: center;
  background-color: rgb(51, 102, 255);
  padding-inline: 0px;
  position: sticky;
  top: 0;
  width: 100%;
  height: 90px;
  justify-content: center;
  border-bottom: 1px solid rgb(201, 199, 199);
  transition: background-color 0.3s ease;
  &.scrolled {
    background-color: #f5f5f5;
  }
  @media (max-width: $xl) {
    padding-inline: 50px;
  }
  @media (max-width: $lg) {
    padding-inline: 25px;
  }
  .primary-text {
    color: white !important;
  }

  .site-header-row {
    justify-content: space-between;
    flex-direction: row !important;
    flex-wrap: wrap;
    display: flex;
    width: 1000px;
    height: 69px;
    align-items: center;
  }
  .site-header-row-left {
    column-gap: 40px;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .site-header-port {
    background-color: $black;
    padding: 10px 5px 10px 5px;
    color: $white;
    border-radius: 10px;
    font-size: 16px;
  }
  .site-header-item {
    color: white;
    font-size: 16px;
    font-family: Montessart;
  }
  .site-header-item:lang(ar) {
    font-family: Tajawal;
  }

  .col-logo {
    justify-content: flex-start;
    width: 100%;
    background-color: green;
    .ant-image {
      align-self: center;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }
  .site-header-logo {
    width: 150px;
  }
}
.site-header-right-container {
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-items: center;
  .site-header-language {
    color: black !important;
    padding: 10px 15px 10px 15px;
    background-color: white;
    font-size: 16px;
    border-radius: 7px;
    margin-left: 20px;
    height: 50px;
    align-items: center;
    justify-content: center;
    display: flex;
    font-family: Tajawal;
    @media (max-width: $lg) {
      padding: 10px 10px 10px 10px;
    }
  }
  .site-header-language:lang(ar) {
    margin-left: 0px;
    margin-right: 20px;
    font-family: Montessart;
  }
  .site-header-supplier {
    color: black !important;
    padding: 10px 15px 10px 15px;
    background-color: white;
    font-size: 16px;
    border-radius: 7px;
    margin-left: 20px;
    height: 50px;
    align-items: center;
    justify-content: center;
    display: flex;
    font-family: Montessart;
    @media (max-width: $lg) {
      padding: 10px 10px 10px 10px;
    }
  }

  .site-header-supplier:lang(ar) {
    margin-left: 0px;
    margin-right: 20px;
    font-family: Tajawal;
    // font-size: 12px;
    padding: 0 10px;
    font-size: 16px;
  }
  .site-header-arrow {
    width: 8px;
    margin: 0px 8px;
    align-self: center;
  }
  .site-header-arrow:lang(ar) {
    transform: rotate(180deg);
  }
}
.link-no-pointer {
  cursor: auto;
}
