@use "color";

$primary: #3366ff;
$secondary: #1fbbec;
$white: #ffffff;
$black: #000000;
$gray: #1e1713;
$background: #fcfcfc;
$grayiesh-background: #e5e5e5;
$gray-background: #1e1713d9;
$selected: #efeff4;
$boxShadow: #00000028;
$blueBorder: #034090;
$primary-light: #1e4b9bcc;
$background-secondary: #1fbbec19;
$error: #d80000;
$background-light-dark: #4b4b4b0a;
$successGreen: #6dc99e;
