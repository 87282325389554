@import "../../theme/colors.scss";
@import "../../theme/breakpoints.scss";

.first-section-background {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  background-color: #d6e0ff;
  position: relative;
  overflow: hidden;
  @media (max-width: 1023px) {
    flex-direction: column;
    min-height: auto;
    height: auto;
  }
  .ing {
    position: absolute;
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
    font: inherit;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    @media (max-width: 1023px) {
      display: none;
    }
  }

  .left-image {
    position: absolute;
    height: auto;
    // width: max-content;
    inset: 0px;
    object-fit: cover;
    color: transparent;
    width: 100%;
    height: 100%;
    padding-bottom: 20px;
  }
  .left-image::before {
    content: "";
    float: left;
    padding-bottom: calc(1 / var(--rui-aspect-ratio) * 100%);
    width: 0px;
  }
  .left-image::after {
    content: "";
    clear: both;
    display: block;
  }
  .right-image {
    right: 0;
    position: absolute;
    width: 30%;
    overflow: hidden;
    top: 0;
    background-color: antiquewhite;
    background-size: cover;
    background-position: center;
  }
}
.first-section {
  display: flex;
  flex-direction: column;
  width: 1000px;
  justify-content: center;
  height: 100%;
  z-index: 999;
  position: absolute;
  min-height: 80vh;
  @media (max-width: 1023px) {
    justify-content: center;
    height: auto;
    margin-top: 0px;
    // width: 100%;
    z-index: unset;
    min-height: auto;
    position: unset;
    width: 80%;
    // background: aliceblue;
  }
  @media (max-width: $lg) {
  }
  @media (max-width: $sm) {
  }
  .first-section-title {
    width: 1000px;
    align-self: center;
    font-family: MontessartBold;
    margin: 0;
    font-size: 96px;
    line-height: 104px;
    text-transform: capitalize;
    white-space: pre-line;
    text-shadow: 0 0 1px #fff, 0 0 1px #fff, 0 0 1px #fff, 0 0 2px #fff,
      0 0 2px #fff, 0 0 4px #fff, 0 0 2px #fff, 0 0 1px #fff;
    width: 900px;
    @media (max-width: 1023px) {
      font-size: 40px;
      line-height: unset;
      text-shadow: unset;
      margin-top: 30px;
      margin-bottom: 10px;
    }
    @media (max-width: $lg) {
      // font-size: 30px;
      width: 100%;
    }
    @media (max-width: $sm) {
      // font-size: 20px;
    }
    @media (max-width: $xs) {
      font-size: 24px;
    }
    @media (max-width: $xxs) {
      // font-size: 22px;
    }
  }
  .first-section-title:lang(ar) {
    font-family: TajawalBold;
    // white-space: pre-line;
    // width: 850px;
  }

  .first-section-subtitle {
    font-size: 14px;
    color: $black;
    line-height: 30px;
    letter-spacing: 0.12em;
    text-transform: uppercase;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 10px;
    align-items: center;
    align-self: center;
    width: 1000px;
    text-shadow: 0 0 1px #fff, 0 0 1px #fff, 0 0 1px #fff, 0 0 2px #fff,
      0 0 2px #fff, 0 0 4px #fff, 0 0 2px #fff, 0 0 1px #fff;
    margin-top: 20px;
    @media (max-width: 1023px) {
      width: 100%;
      text-shadow: unset;
      font-size: 10px;
      margin-bottom: 35px;
    }
    @media (max-width: $lg) {
      font-size: 12px;
      // margin-top: 0px;

      text-align: center;
    }
    @media (max-width: $xl) {
    }
  }
  .first-section-subtitle:lang(ar) {
    letter-spacing: 0em;
    font-family: Tajawal;
  }
  .first-section-stores {
    width: 55%;
    align-self: center;
    justify-content: center;
    gap: 20px;
    flex-direction: row;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    width: 1000px;

    @media (max-width: 1023px) {
      width: 100%;
      display: none;
    }
    @media (max-width: $lg) {
      // align-self: center;
    }
    @media (max-width: $xl) {
      // width: 240px;
    }
    // @media (max-width: $lg) {
    //   // width: 80vw;
    //   flex-direction: column;
    //   justify-content: center !important;
    //   align-items: center;
    //   display: flex;
    // }

    .first-section-stores-icon {
      @media (max-width: 1023px) {
        width: 120px;
        img {
          width: 120px !important;
        }
      }

      @media (max-width: $lg) {
        // width: 20px;
        // align-self: center;
      }
      @media (max-width: $sm) {
        // width: 10px;
        // background-color: aqua;
      }
    }
  }

  .first-section-button {
    border: unset;
    background: black;
    color: white;
    box-shadow: none;
    padding: 15px 35px 15px 35px;
    height: auto;
  }
}
.first-section-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw !important;
  @media (max-width: $xl) {
  }
}
.app-store-logo {
  width: 160px !important;
  box-shadow: 0 0 1px #fff, 0 0 1px #fff, 0 0 1px #fff, 0 0 2px #fff,
    0 0 2px #fff, 0 0 4px #fff, 0 0 2px #fff, 0 0 1px #fff;
  @media (max-width: 1023px) {
    box-shadow: none;
  }
}
.first-section-mobile-text-container {
  display: none;
  @media (max-width: 1023px) {
    display: flex;
    position: absolute;
    right: 0;
    top: 0;
    left: 110px;
    flex-direction: column;
    row-gap: 20px;
    .first-section-mobile-text {
      text-align: left;
      font-family: Montessart;
      font-size: 14px;
      text-transform: uppercase !important;
    }
    .first-section-mobile-text:lang(ar) {
      text-align: left;
      font-family: Tajawal;
    }
  }
  .first-section-stores-mobile {
    // background-color: aqua;
    align-items: flex-start;
    display: flex;
    column-gap: 3px;
    .first-section-stores-icon {
      // background-color: aqua;
      width: 100px;
      img {
        width: 100px !important;
      }
    }
  }
}
.ping {
  display: none;
  @media (max-width: 1023px) {
    display: unset;
    position: relative;
    height: auto;
    width: 390px;
  }
  .first-section-mobile-image {
    width: 100%;
    inset: 0px;
    object-fit: cover;
  }
}
