// @import 'theme/antd-custom-theme.css';
@import "theme/typography.scss";
// @import 'theme/global.scss';

@import "theme/breakpoints.scss";
@import "theme/colors.scss";

@font-face {
  font-family: "Montessart";
  src: url(./assets/fonts/Montserrat.ttf);
  font-weight: normal;
}
@font-face {
  font-family: "MontessartBold";
  src: url(./assets/fonts/MontserratBold.otf);
  font-weight: 900;
}
@font-face {
  font-family: "Tajawal";
  src: url(./assets/fonts/Tajawal.ttf);
  font-weight: normal;
  // unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
  //   U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
  //   U+FEFF, U+FFFD;
  // ascent-override: 90%;
}
@font-face {
  font-family: "TajawalBold";
  src: url(./assets/fonts/Tajawal-ExtraBold.ttf);
  font-weight: bold;
  // unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
  //   U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
  //   U+FEFF, U+FFFD;
  // ascent-override: 90%;
}
body {
  font-family: "Montessart" !important;
  display: contents;
  // color: red !important;
  p {
    margin-block: unset;
  }
}

body:lang(ar) {
  font-family: "Tajawal" !important;
  display: contents;
}
